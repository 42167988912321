.wave-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	pointer-events:none;
}

.custom-shape-divider-bottom-1648840681 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1648840681 svg {
    position: relative;
    display: block;
    width: calc(156% + 1.3px);
    height: 202px;
}
