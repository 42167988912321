.title-container {
	position: relative;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: fit-content;
}
.title-nav{
	height:80px;
}
.title-content {
	width:80%;
}
.cli-comment{
	color: gray;
}
.cli-container{
	color: #fff;
	font-size: 60px;
	min-height:260px;
}
.cli-firstname{
	font-weight: bold;
}

.title-btns{
	display: flex;
	gap: 15px;
	padding:20px 0;
	margin-bottom: 201px;
}

.btn {
	color: #282c34;
	text-align: center;
	border: 3px solid #282c34;
	font-size:18px;
	font-weight:bold;
	border-radius: 50px;
	background-color: lightgreen /*rgba(0,0,0,10%)*/;
	width: 120px;
	padding: 11px 0;
	transition: 0.3s;
}

.btn-out {
	color: #fff;
	background-color: #282c34;
	border-color: lightgreen;
	height: 47px;
}

.btn:hover{
	transition: 0.3s;
	background-color: #282c34;
	border-color: lightgreen;
	color: #fff;
	cursor: pointer;
}

.btn-out:hover{
	border-color: #fff;
	color: lightgreen;
}

* {
	text-align: left;
}


@keyframes resize-logo {
	from {font-size:60px}
	to {font-size:100px}
}

.custom-shape-divider-bottom-1648840680 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1648840680 svg {
    position: relative;
    display: block;
    width: calc(156% + 1.3px);
    height: 202px;
}

.custom-shape-divider-bottom-1648840680 .shape-fill {
    fill: #FFFFFF;
}
