.projects-container{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.projects-content{
		margin-bottom:120px;
}

.project-container {
	margin: 40px 20px;
	max-width:1000px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.project-content{
	position: relative;
	width:50%;
	left: -50px;
}
.project-container *{
	text-align:right;
}
.desc-container{
	padding: 8px;
	background-color: lightgreen;
	border-radius: 5px;
	color: #282c34;
}

.project-container img {
	border-radius: 5px;
	flex-grow:1;
	width:50%;
	max-width: 100%;
	height: auto;
}

.projects-techstack{
	display: flex;
	margin: 5px 0 0 0;
	flex-direction: row;
	justify-content: flex-end;
	flex-wrap: wrap;
}
.projects-tech {
	background-color: #282c34;
	padding:4px;
	margin: 0 3px 0 0;
	border-radius:20px;
	color: white;
}

.projects-tech:hover{
	transition: 0.2s;
	background-color: lightgreen;
	color: #fff;
}

.project-container.left{
	flex-direction: row-reverse;
}



.project-title-container{
	display: flex;
	align-items:center;
	flex-direction: row-reverse;
	gap: 10px;
}
.project-title-container a{
	text-decoration:none;
	color: inherit;
	font-size:20px;
	transition: 0.2s;
}
.project-title-container a:hover{
	color: lightgreen;
	font-size:21px;
	transition: 0.2s;
}

.project-container.left .project-title-container{
	flex-direction: row;
}

.project-container.left *{
	text-align:left !important;
}

.project-container.left .project-content{
	left: +50px;
}

.project-container.left .projects-techstack{
	justify-content: flex-start !important;
}

.projects-more-container{
	margin: 0 0 80px 0;
	text-align:right;
}

.projects-more-container button{
	margin-right: 20px;
	color: #fff;
	text-align: center;
	border: 3px solid lightgreen;
	font-size:18px;
	font-weight:bold;
	border-radius: 50px;
	background-color: lightgreen /*rgba(0,0,0,10%)*/;
	width: 120px;
	padding: 11px 0;
	transition: 0.3s;
}

.projects-more-container button:hover{
	transition: 0.3s;
	background-color: #fff;
	color: #282c34;
	cursor: pointer;
}
