.fancy-input{
	height: 40px;
	margin-top:15px;
	width: 100%;
	position:relative;
}

.fancy-input .box{
 outline: 0;
}

.fancy-input .box{
	height: 100%;
	width: 100%;
	border: none;
	font-size: 17px;
	border-bottom: 2px solid silver;
}

.fancy-input label{
	position: absolute;
	top: 10px;
	left: 0;
	color: gray;
	pointer-events: none;
	transition: all 0.3s ease;
}

.fancy-input input:focus ~ label,
.fancy-input input:valid ~ label,
.not-empty ~ label{
	transform: translateY(-20px);
	font-size: 14px;
}


.fancy-input textarea:focus ~ label,
.fancy-input textarea:valid ~ label{
	transform: translateY(-26px);
	font-size: 14px;
}

textarea {
	resize: none;
	width: 100%;
	height: 100% !important;
}

.fancy-area{
	margin-top:20px;
	height: 120px;
}
