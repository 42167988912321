.heading-container {
	display:flex;
	align-items:center;
	flex-direction:column;
}
.heading-container h1{
	margin: 20px 0px 10px 0px;
}
.header-seperator{
	display: flex;
	align-items: center;
	text-align: center;
	position: relative;
	width: 180px;
	margin: 10px 0 0;
}
.seperator-line{
	width: 100%;
	height:2px;
	background-color: black;
}
.seperator-blob{
	height: 10px;
	width: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
}

.seperator-blob div{
	width: 35px;
	border-radius: 10px;
  background-color: lightgreen;
}


.inv-heading .seperator-blob div{
	background-color: white !important;
}
