
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}

.App, .App-header, #root {
  min-height: 100vh;
  min-width: 100vw;
}

.h-wrapper {
	max-width:1000px;
}

.keep-scroll {
  position: sticky;
  bottom: 0;
  translate: 0 60%;
}

@media only screen and (max-width: 898px){
.h-wrapper {
	width: 80%;
	max-width:800px;
}

.techstack-content{
	flex-direction: column;
}
.techstack-grid{
	grid-template-columns: 150px 150px 150px !important;
} 
.tech{
	font-size: 16px !important;
}

.techstack-more {
	width: 100% !important;
}

.techstack-more h3 {
	width: 50%;
	min-width: 200px !important;
	text-align: right;
}
.techstack-more{
	flex-direction:row !important;
	justify-content:center;
	align-items: center;
}
.techstack-more-content {
	min-width: 50%;
	margin-top: 25px !important;
	justify-content:center;
	align-items: center;
}	
.techstack-more-content *{
	margin-left: 20px;
}

.project-container {
	flex-direction: column !important;
	margin-bottom:80px !important;
}
.project-container img{
	width: 100% !important;
	border-radius: 15px !important;
}
.project-content{
	left: 0 !important;
	width: 100% !important;
}

.form-content {
	width: 100% !important;
	max-width: 100% !important;
}

.social-icon *{
	font-size: 40px !important;
}
}

@media only screen and (max-width: 525px){
	.cli-container{
		min-width: 100% !important;
		font-size: 40px !important;
	}
	.techstack-grid{
		grid-template-columns: 150px 150px !important;
	} 

	.techstack-more h3 {
		font-size: 16px;
	}

	.techstack-more-content p{
		font-size: 22px !important;
	}
	.contact-social a {
		font-size: 15px;
	}
	.contact-horizontal{
		justify-content:center;
		align-items: center;
		flex-direction: column !important;
	}
	.contact-note {
		margin:0 !important;
		width: 100% !important;
	}
	.contact-note h3{
		text-align:center;
	}
	.contact-info{
		width: 100% !important;
		display: flex;
		justify-content:center;
		align-items:center;
		flex-direction:column;
	}
}
