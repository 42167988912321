.form-container{
  min-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color:  lightgreen;
  overflow-x: scroll;
  flex-wrap: no-wrap;
}

.form-inner{
  min-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color:  lightgreen;
}

.form-content {
  background-color: #fff;
  min-width: 50%;
  max-width: 90%;
  min-height: 400px;
  margin-top: 20px;
  transition: 0.8s;
  border-radius: 20px;
}

.hidden{
  position: relative;
  transform: translate(0, 900px);
  transition: 0.8s;
}

.form-content form {
  margin: 10px;
}


.submit-btn {
	margin-top: 10px;
	color: lightgray;
	text-align: center;
	border: 3px solid lightgray;
	font-size:18px;
	font-weight:bold;
	border-radius: 10px;
	background-color: white;
	width: 120px;
	padding: 11px 0;
	transition: 0.3s;
}


.ready:hover {
	transition: 0.3s;
	background-color: lightgreen;
	border-color: #282c34;
	cursor: pointer;
}


.ready{
	color: #282c34;
	border: 3px solid #282c34;
}

.status-box{
  background-color: lightgreen;
  border-radius: 15px;
  margin: 10px 10px 0 10px;
  min-height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} 

.status-err{
  background-color: #ff4646;
  color: white;
  min-height: 80px;
}

.status-box h3, .status-box p {
  margin: 0;
  padding:0;
}
